import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconRightArrow(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M10 6.6665L13.3333 9.99984L10 13.3332"
        stroke="#C0C9CE"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.66675 2.6665V7.33317C2.66675 8.04041 2.9477 8.71869 3.4478 9.21879C3.94789 9.71889 4.62617 9.99984 5.33341 9.99984H13.3334"
        stroke="#C0C9CE"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export const IconRightArrow = forwardRef(_IconRightArrow)
