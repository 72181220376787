import styled from 'styled-components'

import { mainColors } from '../../../styles/colors'
import { _M_Medium } from '../../../styles/typography'

export const GroupLabel = styled.div`
  ${_M_Medium};
  color: ${mainColors.neutralExtraDark};
  background-color: ${mainColors.neutralExtraLight};
  padding: 12px 18px;
`
