import { useCallback, useEffect, useMemo, useState } from 'react'

import { CheckPickerItemTypeNew } from './CheckPicker.types'

const shouldGroupOptions = <OptionType extends string>(
  options: CheckPickerItemTypeNew<OptionType>[],
) => options.some(option => option.groupBy)

const groupOptions = <OptionType extends string>(options: CheckPickerItemTypeNew<OptionType>[]) =>
  options.reduce<{ [key: string]: CheckPickerItemTypeNew<OptionType>[] }>((acc, option) => {
    const key = option.groupBy || 'ungrouped'
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(option)
    return acc
  }, {})

interface UseOptionsProps<OptionType extends string> {
  options: CheckPickerItemTypeNew<OptionType>[]
  value?: OptionType[]
  searchable?: boolean
  isMenuOpen: boolean
}

export const useCheckPickerOptions = <OptionType extends string>({
  options,
  value = [],
  searchable,
  isMenuOpen,
}: UseOptionsProps<OptionType>) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>()

  useEffect(() => {
    if (!isMenuOpen) {
      setSearchTerm(undefined)
    }
  }, [isMenuOpen])

  const mappedOptions = useMemo(() => {
    const selectedOptions = options.filter(option => value.includes(option.value))

    const unselectedOptions = options.filter(option => !value.includes(option.value))

    const groupedOptions = shouldGroupOptions(options)
      ? groupOptions(unselectedOptions)
      : { ungrouped: unselectedOptions }

    return { selectedOptions, groupedOptions }
  }, [options, value])

  const searchedOptions = useMemo(() => {
    if (!searchable) return mappedOptions.groupedOptions

    const lowerSearchTerm = (searchTerm || '')?.toLowerCase().trim()

    if (shouldGroupOptions(options)) {
      return Object.fromEntries(
        Object.entries(mappedOptions.groupedOptions).map(([key, group]) => [
          key,
          group.filter(option => option.label.toLowerCase().includes(lowerSearchTerm)),
        ]),
      )
    } else {
      return {
        ungrouped: mappedOptions.groupedOptions.ungrouped.filter(option =>
          option.label.toLowerCase().includes(lowerSearchTerm),
        ),
      }
    }
  }, [mappedOptions, searchTerm, searchable])

  const handleSearchTerm = useCallback((newTerm?: string) => {
    if (newTerm && newTerm.length >= 1) {
      setSearchTerm(newTerm)
    } else {
      setSearchTerm('')
    }
  }, [])

  return {
    mappedOptions,
    searchedOptions,
    handleSearchTerm,
  }
}
