import styled from 'styled-components'

import { _S_Normal } from '../../../../styles'
import { FlexContainer } from '../../../FlexContainer'

export const TagsWrapper = styled(FlexContainer)`
  flex-wrap: wrap;
  gap: 5px;
  max-width: 100%;

  span {
    min-width: fit-content;
  }
`

export const Title = styled.span`
  ${_S_Normal};
`

export const InputWrapper = styled(FlexContainer)`
  padding: 0 10px;
`
