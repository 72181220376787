import React, { FC } from 'react'

import { IconAlert, IconSuccess } from '@avantstay/backoffice-vectors'

import { mainColors, semanticColors } from '../../styles'
import * as S from './InfoMessage.styles'
import { InfoMessageProps } from './InfoMessage.types'

export const InfoMessage: FC<InfoMessageProps> = ({
  className,
  margin,
  message,
  messageType,
  showIcon = false,
  dataCy,
  onClick,
}) => {
  const chooseIcon = () => {
    const availableIcons = {
      error: <IconAlert fill={semanticColors.failureMedium} />,
      success: <IconSuccess />,
      warning: <IconAlert fill={mainColors.neutralLight} />,
      risky: <IconAlert fill={semanticColors.warningDark} />,
      accent: <IconAlert fill={mainColors.accentDark} />,
    }

    return availableIcons[messageType]
  }

  return (
    <S.InfoMessageContainer
      className={className}
      margin={margin}
      type={messageType}
      data-cy={dataCy}
      onClick={() => onClick?.()}
    >
      {showIcon && chooseIcon()}
      {message}
    </S.InfoMessageContainer>
  )
}
