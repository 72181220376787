import { FC, ReactElement } from 'react'

import { mainColors, semanticColors, Tooltip } from '@avantstay/backoffice-core'
import {
  IconChecklistChecked,
  IconChecklistEmpty,
  IconChecklistHalfChecked,
} from '@avantstay/backoffice-vectors'

import * as S from './ChecklistStatus.styles'
import { ChecklistStatusView } from './ChecklistStatus.types'

interface CheckListStatusProps {
  checklistStatus: ChecklistStatusView
}

const iconByChecklistStatus: Record<string, ReactElement> = {
  [ChecklistStatusView.completed]: <IconChecklistChecked />,
  [ChecklistStatusView.inProgress]: <IconChecklistHalfChecked />,
  [ChecklistStatusView.notStarted]: <IconChecklistEmpty />,
  [ChecklistStatusView.notNeeded]: <IconChecklistChecked />,
}

const iconColorByChecklistStatus: Record<string, string> = {
  [ChecklistStatusView.completed]: semanticColors.successMedium,
  [ChecklistStatusView.inProgress]: semanticColors.successMedium,
  [ChecklistStatusView.notStarted]: mainColors.white,
  [ChecklistStatusView.notNeeded]: mainColors.neutralDark,
}

const titleByChecklistStatus: Record<string, string> = {
  [ChecklistStatusView.completed]: 'Completed',
  [ChecklistStatusView.inProgress]: 'In Progress',
  [ChecklistStatusView.notStarted]: 'Not Started',
  [ChecklistStatusView.notNeeded]: 'Not Needed',
}

export const ChecklistStatus: FC<CheckListStatusProps> = ({ checklistStatus }) => {
  return (
    <Tooltip content={titleByChecklistStatus[checklistStatus]} placement="top">
      <S.IconWrapper color={iconColorByChecklistStatus[checklistStatus] ?? mainColors.neutralDark}>
        {iconByChecklistStatus[checklistStatus] ?? <IconChecklistEmpty opacity={0.4} />}
      </S.IconWrapper>
    </Tooltip>
  )
}
