import styled from 'styled-components'

import { mainColors } from '../../styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Title = styled.div<{ $titleDirection?: string }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ $titleDirection }) => $titleDirection};
  gap: 9px;
  cursor: pointer;

  svg {
    color: ${mainColors.neutralExtraDark};
  }
`
