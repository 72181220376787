import { CustomFieldRestriction } from '@avantstay/arriere-clients/dist/arriereBackoffice'

export const mainColors = {
  // primary
  primaryDark: '#02020A',
  primaryMedium: '#041F32',

  // accent
  accentExtraDark: '#0960D9',
  accentDark: '#0A6BF1',
  accentMedium: '#3788F7',
  accentLight: '#53ACFF',
  accentLightMedium: '#BADEFF',
  accentExtraLight: '#DEECFF',
  accentExtremelyLight: '#EDF5FF',

  // neutral
  neutralExtremelyDark3: '#54656E',
  neutralExtremelyDark2: '#5F727C',
  neutralExtremelyDark: '#6A7F8A',
  neutralExtraDark: '#94A4AD',
  neutralDark: '#C0C9CE',
  neutralMedium: '#E9EEF1',
  neutralLight: '#F2F5F7',
  neutralExtraLight: '#FAFBFC',

  // basic
  white: '#FFFFFF',
  black: '#000000',
}

export const semanticColors = {
  // failure
  failureDark: '#D0154E',
  failureMedium: '#EC4678',
  failureLight: '#F5A3BC',
  failureMediumLight: 'hsl(340,90%,90%)',
  failureExtraLight: '#FFF0F5',

  // success
  successDark: '#17826F',
  successMedium: '#22C3A6',
  successLight: '#97E8D9',
  successLightMedium: '#C0F1E8',
  successExtraLight: '#E1FAF5',

  // warning
  warningExtraDark: '#E58A00',
  warningDark: '#FF9E0D',
  warningMedium: '#FFBD5A',
  warningLight: '#FFD89E',
  warningLightMedium: '#FFF3E2',
  warningExtraLight: '#FFF8EE',
}

export const riskChannelsColors = {
  // risk
  riskVeryHighDark: '#8E041D',
  riskVeryHigh: '#C51A39',
  riskVeryHighLight: '#F6DDE1',
  riskHigh: '#FF9E0D',
  riskMedium: '#FFCC00',
  riskLow: '#22C3A6',
  riskUndefined: '#ACB8BF',
  riskMediumLight: '#FFFAE6',

  // channels
  channelAirbnb: '#FA3256',
  channelAirbnbDark: '#EA062F',
  channelAllegiantAir: '#3788F7',
  channelBonotel: '#62297B',
  channelBookingCom: '#0B3FC7',
  channelExpedia: '#FFD542',
  channelFrontdesk: '#DF3B7A',
  channelGoogle: '#3788F7',
  channelHopper: '#E9716B',
  channelVRBO: '#22C3A6',
  channelHomesAndVillas: '#E9986D',
  channelHomeAway: '#22C3A6',
  channelHomeAwayDark: '#1DA58C',
  channelHomeToGo: '#4d21b7',
  channelMarriott: '#FCBD5E',
  channelPersona: '#8652FF',
  channelPersonaLight: '#F0EBFD',
  channelNonPayingGuest: '#B7C8D0',
  channelOther: mainColors.neutralExtraDark,
  channelQuintess: '#3D5D78',
  channelRentalsUnited: '#382D7A',
  channelStayHvn: '#CEB06B',
  channelStripe: '#635BFF',
  channelTheWesley: '#D87D8E',
  channelTopVillas: '#191A24',
  channelTripAdvisor: '#6FDCA6',
  channelWebsite: '#53C3D0',
  channelVacationRenter: '#003C71',
  channelVoyage: '#E9EEF1',
  channelVoyageDark: '#041F32',
  channelWebsiteDark: '#33AEBC',
  channelMobile: '#022B54',
  channelAcquisitionWebsites: '#972DB2',
  channelBnbFinder: '#EF43BF',
  channelBlueGroundDark: '#3D699A',
  channelBlueGroundLight: '#DAE5EC',
}

export const restrictionColor = {
  [CustomFieldRestriction.essential]: semanticColors.warningMedium,
  [CustomFieldRestriction.optional]: mainColors.neutralDark,
  [CustomFieldRestriction.required]: semanticColors.failureMedium,
}

export const shadowColors = {
  blur: '#E8EBED',
  neutralBlur: '#ACB7BF',
  accentShadow: '#657A87',
  neutralShadow: '#AACCBB',
}

export const colorByChannelSource = {
  Airbnb: riskChannelsColors.channelAirbnb,
  HomeAway: riskChannelsColors.channelHomeAway,
  Vrbo: riskChannelsColors.channelVRBO,
  'Rentals United': riskChannelsColors.channelRentalsUnited,
  Website: riskChannelsColors.channelWebsite,
  Direct: riskChannelsColors.channelWebsite,
  'Owner Stay': riskChannelsColors.channelNonPayingGuest,
  'Non Paying Guest': riskChannelsColors.channelNonPayingGuest,
  Other: riskChannelsColors.channelOther,
  Marriott: riskChannelsColors.channelMarriott,
  'Booking Com': riskChannelsColors.channelBookingCom,
  'The Wesley': riskChannelsColors.channelTheWesley,
  'Home To Go': riskChannelsColors.channelOther,
  Expedia: riskChannelsColors.channelExpedia,
  Mobile: riskChannelsColors.channelMobile,
  'Paying Guest Of Owner': riskChannelsColors.channelOther,
  'Employee Stay': riskChannelsColors.channelOther,
}

export const tapeChartColors = {
  notPaidPrimaryColor: '#EC4678',
  notPaidSecondaryColor: '#FFF1F6',
  fullyPaidPrimaryColor: '#3BCD7E',
  fullyPaidSecondaryColor: '#EBFAF2',
  partiallyPaidPrimaryColor: '#EC4678',
  partiallyPaidSecondaryColor: '#FFF1F6',
  overPaidPrimaryColor: '#8652FF',
  overPaidSecondaryColor: '#F0EBFD',
  highRiskPrimaryColor: '#FFF7FA',
  highRiskSecondaryColor: '#FADAE4',
}

export const externalColors = {
  airbnbLogo: '#FF5A5F',
  bookingComLogo: '#0C3B7C',
  expediaLogo: '#FDDB32',
  hopperLogo: '#ED6869',
  marriottLogo: '#C6003D',
  quintessLogo: '#2C5370',
  vrboLogo: '#0E214B',
}
