import React from 'react'

import { Margin } from '../../../Margin'
import { OptionProps, Size } from '../../SelectField.types'
import * as S from './Option.styles'
import { OptionWithTooltip } from './OptionWithTooltip'

interface SingleOptionProps<OptionValueType> {
  option: OptionProps<OptionValueType>
  isSelected?: boolean
  size: Size
  shouldShowTooltip?: boolean
  onOptionClick: (option: OptionProps<OptionValueType>) => void
}

export function Option<OptionValueType>(props: SingleOptionProps<OptionValueType>) {
  const { option, isSelected, size, shouldShowTooltip, onOptionClick } = props

  if (typeof option.label === 'string' && shouldShowTooltip) {
    return <OptionWithTooltip {...props} />
  }

  return (
    <S.Option
      size={size}
      isSelected={isSelected}
      isDisabled={option.isDisabled}
      onClick={() => !option.isDisabled && onOptionClick(option)}
    >
      {option.data?.dot && <S.Dot color={option.data?.dot} />}
      {option.data?.icon ? option.data.icon : null}
      {option.label}
      {option.subtitle || option.data?.subtitle ? (
        <S.OptionSubtitle>
          <Margin left={10} right={10}>
            •
          </Margin>
          {option.subtitle || option.data?.subtitle}
        </S.OptionSubtitle>
      ) : null}
    </S.Option>
  )
}
