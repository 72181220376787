import { debounce } from 'lodash'
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'

import { IconClear, IconSearch } from '@avantstay/backoffice-vectors'

import { DEBOUNCE_TIME, MIN_INPUT_LENGTH_TO_SEARCH } from '../../constants'
import { Margin } from '../Margin'
import { TextField } from '../TextField'
import * as S from './SearchInput.styles'
import { SearchInputProps } from './SearchInput.types'

export const SearchInput: FC<SearchInputProps> = ({
  hasSearchIcon = true,
  hasClearIcon = true,
  minInputLengthToSearch = MIN_INPUT_LENGTH_TO_SEARCH,
  onChange,
  searchTerm = '',
  width,
  inputTextAlign,
  iconSearchSize,
  ...textFieldProps
}) => {
  // Holds the current value before syncing with store to search for it (debounced)
  const [localTermFilter, setLocalTermFilter] = useState(searchTerm)

  const debouncedOnChange = useMemo(() => debounce(onChange, DEBOUNCE_TIME), [onChange])

  const handleReset = () => {
    setLocalTermFilter('')
    onChange('')
  }

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value
    const validTerm = term.trim()

    setLocalTermFilter(term)

    if (validTerm.length === 0) {
      handleReset()
    }

    event.persist()
    debouncedOnChange(validTerm)
  }

  const clearButton = () =>
    hasClearIcon && localTermFilter.length > 0 ? (
      <IconClear aria-label="Clear search filter" role="button" onClick={handleReset} />
    ) : null

  const searchIcon = () =>
    hasSearchIcon ? (
      <Margin right={4}>
        <IconSearch {...iconSearchSize} />
      </Margin>
    ) : null

  useEffect(() => {
    if (localTermFilter && !searchTerm) {
      handleReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  const Component = textFieldProps.mask ? TextField.SmallMasked : TextField.Small

  return (
    <S.SearchInputContainer width={width} inputTextAlign={inputTextAlign}>
      <Component
        value={localTermFilter}
        onChange={handleOnInputChange}
        inputLeftIcon={searchIcon()}
        inputRightIcon={clearButton()}
        {...textFieldProps}
      />
    </S.SearchInputContainer>
  )
}
