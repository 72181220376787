import React, { useMemo } from 'react'

import { getNewUuid } from '@avantstay/backoffice-core'

import {
  IDragAndDropGridProps,
  IDragAndDropResultProps,
} from '@new/__global/components/DragAndDropGrid/_types'
import { DragAndDropWrapper } from '@new/__global/components/DragAndDropGrid/DragAndDropWrapper/DragAndDropWrapper'
import { chunkItems } from '@new/__global/components/DragAndDropGrid/util/chunkItems'

export function DragAndDropGrid<T>({
  items,
  itemsPerLine,
  direction,
  renderItem,
  onDragEnd,
}: IDragAndDropGridProps<T>) {
  const chunks = useMemo(
    () => chunkItems(itemsPerLine, items, () => getNewUuid()),
    [items, itemsPerLine],
  )

  const handleDragEnd = ({ source, destination }: IDragAndDropResultProps): void => {
    if (destination !== null) {
      const { index: sourceIndex } = source
      const { index: destinationIndex } = destination

      if (typeof destinationIndex !== 'number' || destinationIndex === undefined) return

      onDragEnd(sourceIndex, destinationIndex)
    }
  }

  return (
    <DragAndDropWrapper
      chunks={chunks}
      direction={direction}
      render={renderItem}
      onDragEnd={handleDragEnd}
      itemsPerLine={itemsPerLine}
    />
  )
}
