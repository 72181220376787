import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { CheckPickerItemTypeNew, FlexContainer, Tooltip } from '@avantstay/backoffice-core'

import { Label } from '../../../Label'

import * as S from './InputValueWithTags.styles'

interface InputValueWithTagsProps<OptionType extends string> {
  selectedOptions: CheckPickerItemTypeNew<OptionType>[]
  title?: string
  placeholder?: ReactNode
}

const LABEL_FONT_SIZE = 10

function calculateVisibleAndAggregatedOptions<OptionType extends string>(
  selectedOptions: CheckPickerItemTypeNew<OptionType>[],
  containerWidth: number,
) {
  return selectedOptions.reduce<{
    visible: CheckPickerItemTypeNew<string>[]
    aggregated: CheckPickerItemTypeNew<string>[]
    currentWidth: number
  }>(
    (acc, option) => {
      const optionWidth = option.label.length * LABEL_FONT_SIZE
      if (acc.currentWidth + optionWidth <= containerWidth) {
        return {
          ...acc,
          visible: [...acc.visible, option],
          currentWidth: acc.currentWidth + optionWidth,
        }
      } else {
        return {
          ...acc,
          aggregated: [...acc.aggregated, option],
        }
      }
    },
    { visible: [], aggregated: [], currentWidth: 0 },
  )
}

export function InputValueWithTags<OptionType extends string>({
  selectedOptions,
  title,
  placeholder,
}: InputValueWithTagsProps<OptionType>) {
  const [aggregatedOptions, setAggregatedOptions] = useState<CheckPickerItemTypeNew<string>[]>([])
  const [visibleOptions, setVisibleOptions] = useState<CheckPickerItemTypeNew<string>[]>([])
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateVisibleOptions = () => {
      const containerWidth = containerRef.current?.offsetWidth ?? 0
      const sortedOptions = [...selectedOptions].sort(
        (a, b) => a.label.length * 10 - b.label.length * 10,
      )

      const { visible, aggregated, currentWidth } = calculateVisibleAndAggregatedOptions(
        sortedOptions,
        containerWidth,
      )

      setVisibleOptions(visible)
      setAggregatedOptions(aggregated)
    }

    window.addEventListener('resize', updateVisibleOptions)
    updateVisibleOptions()

    return () => {
      window.removeEventListener('resize', updateVisibleOptions)
    }
  }, [selectedOptions])

  return (
    <S.InputWrapper key="custom-input" justify="flex-start" noWrap gap={3}>
      {!selectedOptions.length ? (
        <S.Title>{placeholder}</S.Title>
      ) : (
        <>
          <S.Title>{title}</S.Title>
          <S.TagsWrapper ref={containerRef} full justify="flex-start">
            {visibleOptions.map(option => (
              <Label.Small
                key={`${option.value}-label`}
                value={option.label || ''}
                style={option?.labelStyle}
              />
            ))}

            {aggregatedOptions.length > 0 ? (
              <Tooltip
                key={`+${aggregatedOptions.length}`}
                content={
                  <FlexContainer gap={3} direction="column" noWrap>
                    {aggregatedOptions.map(item => (
                      <Label.Small key={`${item.value}-tooltip`} value={item.label} />
                    ))}
                  </FlexContainer>
                }
                placement="top"
              >
                <Label.Small value={`+${aggregatedOptions.length}`} />
              </Tooltip>
            ) : null}
          </S.TagsWrapper>
        </>
      )}
    </S.InputWrapper>
  )
}
