import React, { useState } from 'react'

import { IconChevronDown, IconChevronUp } from '@avantstay/backoffice-vectors'

import * as S from '../../CheckPickerNew.styles'
import { ChangeOptionProps, CheckPickerItemTypeNew } from '../../CheckPicker.types'

import { Option } from '../Option'

interface OptionsGroupProps<OptionType extends string> {
  groupKey?: string
  groupOptions?: CheckPickerItemTypeNew<OptionType>[]
}

export const OptionsGroup = <OptionType extends string>({
  groupKey,
  groupOptions,
  selectedOptions,
  onSelectOption,
}: OptionsGroupProps<OptionType> & ChangeOptionProps<OptionType>) => {
  const [isGroupCollapsed, setIsGroupCollapsed] = useState(false)

  return (
    <>
      {groupKey !== 'ungrouped' ? (
        <S.GroupTitle onClick={() => setIsGroupCollapsed(!isGroupCollapsed)}>
          {groupKey} {isGroupCollapsed ? <IconChevronDown /> : <IconChevronUp />}
        </S.GroupTitle>
      ) : null}

      {!isGroupCollapsed &&
        groupOptions?.map(option => (
          <Option
            key={`${groupKey}-${option.value}`}
            item={option}
            selectedOptions={selectedOptions}
            onSelectOption={onSelectOption}
          />
        ))}
    </>
  )
}
