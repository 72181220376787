import React, { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { StyledComponent } from 'styled-components'

import * as S from './Label.styles'
import { LabelProps, LabelSizeProps } from './Label.types'

interface LabelComponent
  extends ForwardRefExoticComponent<LabelProps & RefAttributes<HTMLSpanElement>> {
  Small: ForwardRefExoticComponent<LabelProps & RefAttributes<HTMLSpanElement>>
  Default: ForwardRefExoticComponent<LabelProps & RefAttributes<HTMLSpanElement>>
}

function createLabelComponent<T = {}>(
  Component: StyledComponent<any, any, LabelProps>,
  size?: LabelSizeProps,
): ForwardRefExoticComponent<PropsWithoutRef<LabelProps & T> & RefAttributes<HTMLSpanElement>> {
  return forwardRef<HTMLElement, LabelProps & T>(({ value, style, ...props }, ref) => {
    return (
      <Component ref={ref} style={style} {...size} {...props}>
        {value}
      </Component>
    )
  })
}

const Label = createLabelComponent(S.Label) as LabelComponent

Label.Small = createLabelComponent(S.Label, { small: true })
Label.Default = createLabelComponent(S.Label)

export { Label }
