import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInWeeks,
  endOfWeek,
  isFriday,
  isSaturday,
  isValid,
  parse,
  startOfWeek,
  subDays,
  subMonths,
  subYears,
} from 'date-fns'
import slugify from 'slugify'

import { PeriodPresets } from './dateRange'
import { formatDate, parseISODate } from './format'
import { pluralize } from './strings'

type forceEntireWeekendsParamsType = {
  startDate: string | Date
  endDate: string | Date
}

type forceMinDifferenceInDaysParamsType = {
  min: number
  startDate: string | Date
  endDate: string | Date
}

export interface getPeriodFromDatesType {
  fromDate?: Date | string | number
  toDate?: Date | string | number
}

export const getDateSubDaysFromNow = (subdays: number) => {
  return formatDate(subDays(new Date(), subdays))
}

export const getDateAddDaysFromNow = (subdays: number) => {
  return formatDate(addDays(addDays(new Date(), 1), subdays))
}

export const getDateFormatted = (value?: string | Date, format?: string) => {
  return formatDate(value, format || 'EEE, MMM dd, yyyy')
}

export const getDateAndTimeFormatted = (value?: string | Date) => {
  return {
    date: formatDate(value, 'EEE, MMM dd, yy'),
    time: formatDate(value, 'hh:mm a'),
  }
}

export function getPeriodFromDates({ fromDate, toDate }: getPeriodFromDatesType) {
  const from = fromDate ? formatDate(fromDate) : ''
  const to = toDate ? formatDate(toDate) : ''

  const isFromValid = isValid(parseISODate(from))
  const isToValid = isValid(parseISODate(to))

  return `${isFromValid ? from : ''}${isFromValid && isToValid ? '_' : ''}${isToValid ? to : ''}`
}

export function forceEntireWeekends({ startDate, endDate }: forceEntireWeekendsParamsType) {
  const parsedStart = parseISODate(startDate)
  const parsedEnd = parseISODate(endDate)
  return {
    startDate: isSaturday(parsedStart) ? subDays(parsedStart, 1) : parsedStart,
    endDate: isFriday(parsedEnd) ? addDays(parsedEnd, 1) : parsedEnd,
  }
}

export function forceMinDifferenceInDays({
  min,
  startDate,
  endDate,
}: forceMinDifferenceInDaysParamsType) {
  const parsedStart = parseISODate(startDate)
  const parsedEnd = parseISODate(endDate)
  const differenceCount = differenceInDays(parsedEnd, parsedStart)

  if (differenceCount >= min) {
    return { startDate: parsedStart, endDate: parsedEnd }
  }

  return {
    startDate: parsedStart,
    endDate: addDays(parsedEnd, min - differenceCount),
  }
}

export function getNext12Months(mask?: string) {
  const now = new Date()

  return new Array(12).fill(1).map((_, index) => formatDate(addMonths(now, index), mask))
}

export const dateTo12MonthsBefore = (date: string | Date) => {
  const parsedDate = parseISODate(date)

  return formatDate(subYears(parsedDate, 1))
}

export const dateTo6MonthsBefore = (date: string | Date) => {
  const parsedDate = parseISODate(date)
  return formatDate(subMonths(parsedDate, 6))
}

export const dateTo1MonthBefore = (date: string | Date) => {
  const parsedDate = parseISODate(date)
  return formatDate(subMonths(parsedDate, 1))
}

export const timeAgoFromToday = (date: string | Date): string => {
  const today = Date.now()

  const minutesAgo = differenceInMinutes(today, parseISODate(date))
  if (minutesAgo < 60) return `${minutesAgo} ${pluralize(minutesAgo, 'minutes', 'minute')}`

  const hoursAgo = differenceInHours(today, parseISODate(date))
  if (hoursAgo < 24) return `${hoursAgo} ${pluralize(hoursAgo, 'hours', 'hour')}`

  const daysAgo = differenceInDays(today, parseISODate(date))
  if (daysAgo <= 14) return `${daysAgo} ${pluralize(daysAgo, 'days', 'day')}`

  const weeksAgo = differenceInWeeks(today, parseISODate(date))
  if (weeksAgo <= 4) return `${weeksAgo} ${pluralize(weeksAgo, 'weeks', 'week')}`

  const monthsAgo = differenceInCalendarMonths(today, parseISODate(date))

  return `${monthsAgo} ${pluralize(monthsAgo, 'months', 'month')}`
}

export const timeAfterFromToday = (date: string | Date): string => {
  const todayDate = new Date()
  const year = todayDate.getFullYear()
  const month = (todayDate.getMonth() + 1).toString().padStart(2, '0')
  const day = todayDate.getDate().toString().padStart(2, '0')
  const today = new Date(`${year}-${month}-${day}`)

  const _date = parseISODate(date)

  if (today === _date) return 'today'

  const minutesAfter = differenceInMinutes(_date, todayDate)
  if (minutesAfter < 60) return `in ${minutesAfter} ${pluralize(minutesAfter, 'minutes', 'minute')}`

  const hoursAfter = differenceInHours(_date, todayDate)
  if (hoursAfter < 24) return `in ${hoursAfter} ${pluralize(hoursAfter, 'hours', 'hour')}`

  const daysAfter = differenceInDays(_date, todayDate)
  if (daysAfter < 7) return `in ${daysAfter} ${pluralize(daysAfter, 'days', 'day')}`

  const weeksAfter = differenceInWeeks(_date, todayDate)
  if (weeksAfter < 5) return `in ${weeksAfter} ${pluralize(weeksAfter, 'weeks', 'week')}`

  const monthsAfter = differenceInCalendarMonths(_date, todayDate)
  return `in ${monthsAfter} ${pluralize(monthsAfter, 'months', 'month')}`
}

export const getListOfDates = (startDate: string, endDate: string) => {
  const differentBetweenDays =
    differenceInCalendarDays(parseISODate(endDate), parseISODate(startDate)) + 1
  const arraySize = new Array(differentBetweenDays).fill(null)
  return arraySize.map((_, index) => addDays(parseISODate(startDate), index))
}

export const getWeekDates = (week: string) => {
  const date = parse(week, ISO_YEAR_WEEK_FORMAT, new Date())
  return {
    start: startOfWeek(date, { weekStartsOn: 1 }),
    end: endOfWeek(date, { weekStartsOn: 1 }),
  }
}

export const isPeriodPreset = (slug: string): slug is PeriodPresets => {
  return Object.values(PeriodPresets)
    .map(preset => slugify(preset))
    .includes(slug as PeriodPresets)
}

export const UI_DEFAULT_DATE_FORMAT = 'MMM d, yy'
export const SHORT_ISO_DATE_FORMAT = 'yyyy-MM-dd'
export const ISO_MONTH_FORMAT = 'yyyy-MM'
export const FULL_MONTH_NAME_FORMAT = 'MMMM'
export const SHORT_MONTH_NAME_FORMAT = 'MMM'
export const YEAR_FORMAT = 'yyyy'
export const ISO_YEAR_WEEK_FORMAT = "RRRR-'W'II" // 2024-W04
