import styled, { css } from 'styled-components'

import { _M_Normal, zIndex } from '../../styles'
import { mainColors, shadowColors } from '../../styles/colors'
import { medias } from '../../styles/medias'
import { _XS_Bold } from '../../styles/typography'
import { rgba } from '../../utils/cssUtils'

export const LoadingContainer = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
`

export const Container = styled.div<{ $active: boolean; $disabled?: boolean; $small?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 100%;
  height: ${({ $small }) => ($small ? '36px' : '50px')};
  min-height: ${({ $small }) => ($small ? '36px' : '54px')};
  background-color: white;
  border: 2px solid
    ${({ $active }) => ($active ? mainColors.accentMedium : mainColors.neutralMedium)};
  border-radius: 2px;
  cursor: pointer;

  ${medias.LG_SCREEN`
    min-width: 100%;
  `};

  &:focus-within {
    border: 2px solid ${mainColors.accentMedium};
  }

  &:hover {
    border-color: ${({ $active }) => !$active && mainColors.neutralDark};
  }

  ${({ $disabled }) =>
    $disabled
      ? css`
          background-color: ${mainColors.neutralExtraLight};
          border: 2px solid ${mainColors.neutralMedium};
          color: ${mainColors.neutralExtremelyDark};

          &:hover {
            border-color: ${mainColors.neutralMedium};
          }

          &:focus-within {
            border: 2px solid ${mainColors.neutralMedium};
          }
        `
      : null};
`

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${mainColors.neutralLight};
  overflow-y: auto;
  max-height: 140px;
`

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`

export const NoOptions = styled.div`
  color: ${mainColors.neutralDark};
  ${_M_Normal};
  text-align: center;
`

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`

export const GroupTitle = styled.div`
  ${_XS_Bold};
  color: ${mainColors.neutralExtremelyDark};
  text-transform: uppercase;
  height: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DropdownIndicator = styled.div<{ disabled?: boolean }>`
  position: absolute;
  right: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${mainColors.neutralExtraDark};
  cursor: pointer;

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : null};
`

export const DropdownContainer = styled.div`
  background: ${mainColors.white};
  border: 1px solid ${mainColors.neutralLight};
  border-radius: 2px;
  box-shadow: 0 4px 16px ${rgba(shadowColors.blur, 0.4)};
  padding: 12px;
  z-index: ${zIndex.MODAL};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
