import React from 'react'

import { Button } from '../../../Button'
import { Checkbox } from '../../../Checkbox'
import { CheckPickerItemTypeNew, CheckPickerOptionsMenuProps } from '../../CheckPicker.types'
import * as S from '../../CheckPickerNew.styles'
import { Option } from '../Option'
import { OptionsGroup } from '../OptionsGroup'

interface OptionsMenuProps<OptionType extends string>
  extends CheckPickerOptionsMenuProps<OptionType> {
  selectedOptions: CheckPickerItemTypeNew<OptionType>[]
  searchedOptions: { [groupKey: string]: CheckPickerItemTypeNew<OptionType>[] }
}

export const OptionsMenu = <OptionType extends string>({
  value,
  hideMenuActions,
  hideOptionAll,
  customOptionAll,
  selectedOptions,
  searchedOptions,
  onClearSelection,
  onChange,
}: OptionsMenuProps<OptionType>) => {
  const hasSelection = !!value && value.length > 0

  const optionsGroups = Object.entries(searchedOptions)

  const hasOptions = optionsGroups.length > 0 && optionsGroups[0][1].length > 0

  return (
    <>
      <S.MenuWrapper>
        {!hideMenuActions ? (
          <S.ContainerActions>
            {!hideOptionAll ? (
              <Checkbox.Regular
                id={`checkbox-all-${new Date().toString()}`}
                value={customOptionAll?.value}
                title={customOptionAll?.label}
                checked={!hasSelection}
                onChange={onClearSelection}
              />
            ) : null}

            {onClearSelection ? (
              <Button.Flat disabled={!hasSelection} onClick={onClearSelection}>
                Clear
              </Button.Flat>
            ) : null}
          </S.ContainerActions>
        ) : null}
      </S.MenuWrapper>
      {selectedOptions?.length > 0 && (
        <S.MenuWrapper>
          {selectedOptions.map(selectedOption => (
            <Option
              key={`${selectedOption.value}-selected`}
              item={selectedOption}
              selectedOptions={value}
              onSelectOption={onChange}
            />
          ))}
        </S.MenuWrapper>
      )}

      <S.OptionsWrapper>
        {hasOptions ? (
          optionsGroups.map(([groupKey, groupOptions]) => (
            <OptionsGroup
              key={groupKey}
              groupKey={groupKey}
              groupOptions={groupOptions}
              selectedOptions={value}
              onSelectOption={onChange}
            />
          ))
        ) : (
          <S.NoOptions>no options</S.NoOptions>
        )}
      </S.OptionsWrapper>
    </>
  )
}
