import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconChecklistChecked = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" {...props} ref={ref}>
    <circle cx="8.66667" cy="8" r="8" fill="currentColor" />
    <path
      d="M12.6666 5L7.16665 10.5L4.66665 8"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconChecklistChecked = forwardRef(_IconChecklistChecked)
