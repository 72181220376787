import React, { useRef, useState } from 'react'

import { MiddleEllipsis, Tooltip } from '@avantstay/backoffice-core'

import { Margin } from '../../../Margin'
import { OptionProps, Size } from '../../SelectField.types'
import * as S from './Option.styles'

interface OptionWithTooltipProps<OptionValueType> {
  option: OptionProps<OptionValueType>
  isSelected?: boolean
  size: Size
  onOptionClick?: (option: OptionProps<OptionValueType>) => void
}

export function OptionWithTooltip<OptionValueType>({
  option,
  isSelected,
  size,
  onOptionClick,
}: OptionWithTooltipProps<OptionValueType>) {
  const [nameIsCollapsed, setNameIsCollapsed] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <Tooltip
      content={<S.TooltipContent>{option.label}</S.TooltipContent>}
      placement="top"
      disabled={!nameIsCollapsed}
    >
      <S.Option
        size={size}
        isSelected={isSelected}
        isDisabled={option.isDisabled}
        onClick={() => !option.isDisabled && onOptionClick?.(option)}
      >
        {option.data?.dot && <S.Dot color={option.data?.dot} />}
        {option.data?.icon ? option.data.icon : null}
        <S.OptionLabel ref={containerRef}>
          {typeof option.label === 'string' ? (
            <MiddleEllipsis
              tailLength={4}
              data={option.label}
              onCollapseChange={isCollapsed => setNameIsCollapsed(isCollapsed)}
            />
          ) : (
            option.label
          )}
        </S.OptionLabel>
        {option.subtitle || option.data?.subtitle ? (
          <S.OptionSubtitle>
            <Margin left={10} right={10}>
              •
            </Margin>
            {option.subtitle || option.data?.subtitle}
          </S.OptionSubtitle>
        ) : null}
      </S.Option>
    </Tooltip>
  )
}
