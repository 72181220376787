import styled from 'styled-components'

import { mainColors, shadowColors, zIndex } from '../../styles'
import { rgba } from '../../utils'
import { Badge } from '../Badge'

export const BadgesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  height: max-content;
  margin-top: 6px;
  position: relative;
  gap: 2px;

  & > div {
    margin-top: 4px;
  }
`

export const DisplayedBadge = styled(Badge)<{ minWidth?: string; maxWidth?: string }>`
  overflow: hidden;
  width: auto;
  display: inline-block;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth} !important;`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth} !important;`}
`

export const HiddenBadgesContainer = styled.div`
  max-width: 200px;
  width: max-content;
  background: ${mainColors.white};
  border: 1px solid ${mainColors.neutralLight};
  border-radius: 2px;
  box-shadow: 0 4px 16px ${rgba(shadowColors.blur, 0.4)};
  padding: 12px;
  z-index: ${zIndex.MODAL};
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  height: max-content;
  gap: 8px;
`
