import styled from 'styled-components'

const ICON_SIZE = 16

export const IconWrapper = styled.div<{ color: string }>`
  color: ${({ color }) => color};

  svg {
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    min-width: ${ICON_SIZE}px;
    min-height: ${ICON_SIZE}px;
  }
`
