import { OnlineTravelAgency } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { BadgeColors, BadgeColorsProps } from '../components'

export const onlineTravelAgencyLabel: Record<string, string> = {
  [OnlineTravelAgency.acqDirect]: 'ACQ Direct',
  [OnlineTravelAgency.airbnb]: 'Airbnb',
  [OnlineTravelAgency.blueGround]: 'Blueground',
  [OnlineTravelAgency.bnbFinder]: 'Bnbfinder',
  [OnlineTravelAgency.bonotel]: 'Bonotel',
  [OnlineTravelAgency.bookingCom]: 'Booking.com',
  [OnlineTravelAgency.capitalOne]: 'Capital One',
  [OnlineTravelAgency.direct]: 'Direct',
  [OnlineTravelAgency.expedia]: 'Expedia',
  [OnlineTravelAgency.google]: 'Google',
  [OnlineTravelAgency.googleTravel]: 'Google Travel',
  [OnlineTravelAgency.homesAndVillas]: 'Homes & Villas',
  [OnlineTravelAgency.homeToGo]: 'HomeToGo',
  [OnlineTravelAgency.hopper]: 'Hopper',
  [OnlineTravelAgency.marriott]: 'Marriott',
  [OnlineTravelAgency.quintess]: 'Quintess',
  [OnlineTravelAgency.rentalsUnited]: 'Rentals United',
  [OnlineTravelAgency.stayHvn]: 'Stay HVN',
  [OnlineTravelAgency.staySense]: 'StaySense',
  [OnlineTravelAgency.topVillas]: 'Top Villas',
  [OnlineTravelAgency.travelStaytion]: 'TravelStaytion',
  [OnlineTravelAgency.tripAdvisor]: 'Trip Advisor',
  [OnlineTravelAgency.vacationRenter]: 'VacationRenter',
  [OnlineTravelAgency.vrbo]: 'VRBO',
  [OnlineTravelAgency.whimstay]: 'Whimstay',
}

export const badgeColorByOnlineTravelAgency: Record<string, BadgeColorsProps> = {
  [OnlineTravelAgency.airbnb]: BadgeColors.channelAirbnb,
  [OnlineTravelAgency.vrbo]: BadgeColors.channelVRBO,
  [OnlineTravelAgency.direct]: BadgeColors.channelWebsite,
}
