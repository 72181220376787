import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconChecklistHalfChecked = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" {...props} ref={ref}>
    <circle cx="8.66667" cy="8" r="7.25" fill="white" stroke="#C0C9CE" strokeWidth="1.5" />
    <path
      d="M0.666666 8C0.666666 12.4183 4.24839 16 8.66667 16V0C4.24839 0 0.666666 3.58172 0.666666 8Z"
      fill="currentColor"
    />
  </svg>
)

export const IconChecklistHalfChecked = forwardRef(_IconChecklistHalfChecked)
