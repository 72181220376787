import React, { Fragment, useMemo } from 'react'

import { InfoWrapper } from '../SelectField.styles'
import {
  OptionProps,
  OptionsGroup,
  OptionsType,
  SelectFieldProps,
  Size,
  ValueType,
} from '../SelectField.types'
import { isMultiValue } from '../SelectField.utils'
import { Option } from './Option'
import * as S from './OptionsList.styles'
import { filterOptionsByInput, hasOptionsGrouping } from './OptionsList.utils'

interface OptionsMenuProps<OptionValueType> {
  filterOption: SelectFieldProps<OptionValueType>['filterOption']
  inputValue: string
  isMulti?: boolean
  value: ValueType<OptionValueType>
  noOptionsMessage: SelectFieldProps<OptionValueType>['noOptionsMessage']
  onOptionClick: (option: OptionProps<OptionValueType>) => void
  options: OptionsType<OptionValueType>
  size: Size
  shouldShowTooltip?: boolean
  skipOptionsFiltering?: boolean
}

export function OptionsList<OptionValueType>({
  filterOption,
  inputValue,
  isMulti,
  value,
  noOptionsMessage,
  onOptionClick,
  options,
  size,
  shouldShowTooltip,
  skipOptionsFiltering,
}: OptionsMenuProps<OptionValueType>) {
  const isSingleSelect = !isMultiValue(value, isMulti)
  const optionsOrGroups = useMemo(() => {
    if (skipOptionsFiltering || (isSingleSelect && !inputValue)) {
      return options
    }

    if (hasOptionsGrouping(options)) {
      // grouped options
      return options.map<OptionsGroup<OptionValueType>>(optionsGroup => ({
        ...optionsGroup,
        options: filterOptionsByInput(optionsGroup.options, inputValue, filterOption),
      }))
    }

    return filterOptionsByInput(options, inputValue, filterOption)
  }, [filterOption, inputValue, isSingleSelect, options, skipOptionsFiltering])

  const getNoOptionsMessage = () =>
    typeof noOptionsMessage === 'function'
      ? noOptionsMessage({ inputValue })
      : (noOptionsMessage ?? 'no options')

  const isOptionSelected = (option: OptionProps<OptionValueType>) => {
    if (!value) {
      return false
    }

    if (!isSingleSelect) {
      return value.some(v => v.value === option.value)
    }

    return option.value === value.value
  }

  const renderNoOptionsInfo = () => (
    <InfoWrapper>{getNoOptionsMessage() || 'No options'}</InfoWrapper>
  )

  const renderOptionsList = (optionsToRender: OptionProps<OptionValueType>[]) => {
    // for multi value we filter-out selected options
    const filteredOptions =
      isSingleSelect || !value || value.length === 0
        ? optionsToRender
        : optionsToRender.filter(option => !isOptionSelected(option))

    if (!filteredOptions.length) {
      return renderNoOptionsInfo()
    }

    return filteredOptions.map((option, index) => {
      const isSelected = isOptionSelected(option)
      return (
        <Option
          key={index}
          option={option}
          shouldShowTooltip={shouldShowTooltip}
          size={size}
          onOptionClick={onOptionClick}
          isSelected={isSelected}
        />
      )
    })
  }

  if (!optionsOrGroups || !optionsOrGroups.length) {
    return renderNoOptionsInfo()
  }

  if (hasOptionsGrouping(optionsOrGroups)) {
    // grouped options case
    return (
      <>
        {optionsOrGroups.map((group, index) => {
          return (
            <Fragment key={`${group.label}-${index}`}>
              <S.GroupLabel>{group.label}</S.GroupLabel>
              {renderOptionsList(group.options)}
            </Fragment>
          )
        })}
      </>
    )
  }
  return <>{renderOptionsList(optionsOrGroups)}</>
}
