import { CheckPicker as CheckPickerLegacy } from './CheckPicker'
import { Container as CheckPickerContainer } from './CheckPicker.styles'
import { CheckPicker as CheckPickerNew } from './CheckPickerNew'

export { CheckPickerContainer }
export * from './CheckPicker.types'
export { checkPickerSearchBy } from './CheckPicker.utils'

const CheckPicker = {
  Small: CheckPickerLegacy.Small,
  Default: CheckPickerLegacy.Default,
  SmallNew: CheckPickerNew.Small,
  DefaultNew: CheckPickerNew.Default,
}

export { CheckPicker }
