import React, { useRef, useState } from 'react'

import { MiddleEllipsis, Tooltip } from '@avantstay/backoffice-core'

import * as S from '../../SelectField.styles'
import { OptionProps, Size } from '../../SelectField.types'

interface SingleValueProps<OptionValueType> {
  value: OptionProps<OptionValueType> | null
  size: Size
}

export function SingleValue<OptionValueType>({ value, size }: SingleValueProps<OptionValueType>) {
  const [nameIsCollapsed, setNameIsCollapsed] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const displayLabel = value?.customInputLabel || value?.label

  if (typeof displayLabel === 'string' && value) {
    return (
      <Tooltip content={displayLabel} placement="top" disabled={!nameIsCollapsed}>
        <S.SingleValue size={size} ref={containerRef}>
          {value?.data?.icon ? value?.data.icon : null}
          <div>
            <MiddleEllipsis
              tailLength={4}
              data={displayLabel}
              onCollapseChange={isCollapsed => setNameIsCollapsed(isCollapsed)}
            />
          </div>
        </S.SingleValue>
      </Tooltip>
    )
  }

  return (
    <S.SingleValue size={size}>
      {value?.data?.icon ? value?.data.icon : null}
      {displayLabel}
    </S.SingleValue>
  )
}
