import arriereBackoffice, {
  ComparisonOperator,
  IDate,
  OnBoardingStatus,
  RatingSorting,
  ReviewPeriodKind,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { MarketReviewsParams } from '@new/domains/ratings/marketReviews/MarketReviews.types'

export interface RegionsQueryFilters {
  fromDate: string | IDate
  toDate: string | IDate
  onBoardingStatus?: OnBoardingStatus[]
  page: number
  size: number
  regionIds?: string[]
  fromNpsScore?: number
  toNpsScore?: number
  alias?: string
}

export default {
  getRegions() {
    return arriereBackoffice.queries.regions({
      __alias: 'getRegions',
      __args: { pagination: { page: 1, size: 200 } },
      total: true,
      results: {
        id: true,
        name: true,
        homeCount: true,
      },
    })
  },
}

export const airbnbGlobalRatingsMetricsFragment = {
  avgAccuracy: true,
  avgCheckIn: true,
  avgCleanliness: true,
  avgCommunication: true,
  avgLocation: true,
  avgOverall: true,
  avgValue: true,
  conversion: {
    conversionRate: true,
    numberOfBookings: true,
  },
  numberOfReviews: true,
}

export const vrboGlobalRatingsMetricsFragment = {
  avgOverall: true,
  avgCheckIn: true,
  avgCleanliness: true,
  avgCommunication: true,
  avgLocation: true,
  avgOnlineListing: true,
  conversion: {
    conversionRate: true,
    numberOfBookings: true,
  },
  numberOfReviews: true,
}

export const npsGlobalRatingsMetricsFragment = {
  numberOfReviews: true,
  score: true,
  conversion: {
    conversionRate: true,
    numberOfBookings: true,
  },
}

export const getAllMarketRatings = async ({ fromDate, toDate, regionIds }: RegionsQueryFilters) => {
  return arriereBackoffice.queries.rating
    .raw({
      __alias: 'getAllMarketRatings',
      ratingMetricGlobal: {
        __args: { from: fromDate, to: toDate, regionIds },
        airbnb: {
          ...airbnbGlobalRatingsMetricsFragment,
        },
        nps: {
          ...npsGlobalRatingsMetricsFragment,
        },
        vrbo: {
          ...vrboGlobalRatingsMetricsFragment,
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors?.length - 1]?.message)
      }

      return data.ratingMetricGlobal
    })
}

export const getMarketRatings = async ({
  fromDate,
  toDate,
  fromNpsScore,
  toNpsScore,
  page,
  size,
  regionIds,
  alias,
}: RegionsQueryFilters) => {
  return arriereBackoffice.queries.rating
    .raw({
      __alias: `${alias || 'getMarketRatings'}`,
      ratingMetricByRegion: {
        __args: {
          from: fromDate,
          to: toDate,
          pagination: { page, size },
          regionIds,
          filters:
            fromNpsScore && toNpsScore
              ? [
                  {
                    column: RatingSorting.npsScore,
                    operator: ComparisonOperator.greaterThanOrEqualTo,
                    value: fromNpsScore,
                  },
                  {
                    column: RatingSorting.npsScore,
                    operator: ComparisonOperator.lessThan,
                    value: toNpsScore,
                  },
                ]
              : [],
        },
        pages: true,
        total: true,
        results: {
          airbnb: {
            ...airbnbGlobalRatingsMetricsFragment,
          },
          region: {
            active: true,
            homesCountingStats: {
              listedCount: true,
            },
            id: true,
            name: true,
          },
          nps: {
            ...npsGlobalRatingsMetricsFragment,
          },
          vrbo: {
            ...vrboGlobalRatingsMetricsFragment,
          },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors?.length - 1]?.message)
      }

      return data
    })
}

export const ratingsOverviewFragment = {
  homeAway: {
    overall: {
      avgStars: true,
      totalRates: true,
    },
    onlineListing: {
      avgStars: true,
    },
    checkIn: {
      avgStars: true,
    },
    communication: {
      avgStars: true,
    },
    cleanliness: {
      avgStars: true,
    },
    location: {
      avgStars: true,
    },
    conversionRate: true,
    numberOfReviews: true,
  },
  airBnb: {
    overall: {
      avgStars: true,
      totalRates: true,
    },
    accuracy: {
      avgStars: true,
    },
    checkIn: {
      avgStars: true,
    },
    communication: {
      avgStars: true,
    },
    cleanliness: {
      avgStars: true,
    },
    location: {
      avgStars: true,
    },
    value: {
      avgStars: true,
    },
    conversionRate: true,
    numberOfReviews: true,
  },
}

export const npsRatingsOverviewFragment = {
  count: true,
  conversionRate: true,
  numberOfBookings: true,
  score: true,
}

export const getMarketReviewsOverview = async (regionId: string, from: string, to: string) => {
  return arriereBackoffice.queries.region
    .raw({
      __alias: 'getMarketReviewsOverview',
      __args: {
        regionId,
      },
      ratings: {
        __args: { from, to },
        ...ratingsOverviewFragment,
      },
      indicators: {
        customerExperience: {
          npsInfo: {
            __args: { from, to },
            ...npsRatingsOverviewFragment,
          },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors?.length - 1]?.message)
      }

      return data
    })
}

export const getMarketReviews = async ({
  homeIds,
  marketId,
  page,
  pageSize,
  source,
  from,
  to,
  terms,
  minRating,
  maxRating,
  categories,
}: MarketReviewsParams) => {
  return arriereBackoffice.queries.region
    .raw({
      __alias: 'getMarketReviews',
      __args: { regionId: marketId },
      name: true,
      homesCountingStats: {
        listedCount: true,
      },
      reviewsByBookingSources: {
        __args: {
          pagination: { page, size: pageSize },
          periodKind: ReviewPeriodKind.reviewCreationDate,
          period: {
            start: from,
            end: to,
          },
          sources: source,
          terms,
          homeIds,
          minRating,
          maxRating,
          categories,
        },
        total: true,
        pages: true,
        sourceFacet: { source: true, count: true },
        results: {
          bookingId: true,
          bookingHash: true,
          source: true,
          guestName: true,
          period: {
            start: true,
            end: true,
          },
          reviews: {
            id: true,
            source: true,
            privateFeedback: true,
            response: true,
            externalReviewId: true,
            createdAt: true,
            booking: {
              guestId: true,
              guestName: true,
            },
            bookingPeriod: {
              start: true,
              end: true,
            },
            categoryReviews: {
              category: true,
              rating: true,
              comment: true,
            },
            home: {
              name: true,
            },
          },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors?.length - 1]?.message)
      }

      return data
    })
}

export const getMarketReviewsSourceStats = async ({
  homeIds,
  marketId,
  page,
  pageSize,
  from,
  to,
  terms,
  minRating,
  maxRating,
  categories,
}: MarketReviewsParams) => {
  return arriereBackoffice.queries.region
    .raw({
      __alias: 'getMarketReviewsSourceStats',
      __args: { regionId: marketId },
      reviewsByBookingSources: {
        __args: {
          pagination: { page, size: pageSize },
          period: { start: from, end: to },
          periodKind: ReviewPeriodKind.reviewCreationDate,
          terms,
          homeIds,
          minRating,
          maxRating,
          categories,
        },
        total: true,
        sourceFacet: { source: true, count: true },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[0]?.message)
      }

      return data
    })
}
