import React, { SyntheticEvent } from 'react'

import { Checkbox } from '../../../Checkbox'
import { ChangeOptionProps, CheckPickerItemTypeNew } from '../../CheckPicker.types'

interface OptionProps<OptionType extends string> {
  item: CheckPickerItemTypeNew<OptionType>
}

export const Option = <OptionType extends string>({
  item,
  selectedOptions,
  onSelectOption,
}: OptionProps<OptionType> & ChangeOptionProps<OptionType>) => {
  const { value, label, dot, subtitle } = item

  const handleSelectOption = (event: SyntheticEvent<HTMLInputElement>) => {
    if (!onSelectOption) return

    const isSelected = (selectedValue: OptionType) => selectedValue === value
    const filterOutCurrentItem = (selectedValue: OptionType) => selectedValue !== value

    const newSelection = selectedOptions?.find(isSelected)
      ? selectedOptions?.filter(filterOutCurrentItem)
      : [...(selectedOptions || []), value]

    onSelectOption(newSelection, event)
  }

  return (
    <Checkbox.Regular
      id={value}
      dot={dot}
      subtitle={subtitle}
      value={label?.toString()}
      title={label}
      checked={selectedOptions?.includes(item.value)}
      onChange={handleSelectOption}
      hasFullWidth
    />
  )
}
