// @ts-nocheck
import { differenceInCalendarISOWeeks, isEqual } from 'date-fns'
import React, { ReactNode, useState } from 'react'
import { StyledComponent } from 'styled-components'

import { DateRangePicker, DateRangePickerBase } from '@avantstay/avantstay-ui'
import { DateRange } from '@avantstay/avantstay-ui/lib/DateRangePicker/DateRangePicker.types'
import { formatDateRange } from '@avantstay/format-date-range'

import { mainColors } from '../../styles'
import {
  formatDate,
  formatDateRangeExtraOptions,
  getDatesFromPeriod,
  getPeriodFromDates,
  parseISODate,
  totalNights,
  useIsTinyMobile,
} from '../../utils'
import { TextField } from '../TextField'
import * as S from './DatePickerField.styles'
import { DatePickerFieldProps, DatePickerSizeProps } from './DatePickerField.types'

const createDatePickerFieldComponent =
  <T,>(Component: StyledComponent<any, any, DatePickerFieldProps>, size?: DatePickerSizeProps) =>
  ({
    afterValue,
    blockedDates,
    children,
    className,
    clearButtonColor = mainColors.accentMedium,
    clearButtonLabel,
    customDatePickerHeaderComponent,
    customPeriodMaxDate,
    customPeriodMinDate,
    dateTooltip,
    dateValue,
    disabled = false,
    disableDaysBeforeToday = false,
    error,
    firstDayOfWeek,
    horizontalAlignment,
    id,
    inputDataCy,
    inputWidthMaxContent,
    labelInternal,
    minRangeLength,
    onChange,
    originalRange,
    placeholder,
    pickerClassName,
    shouldAcceptEmptyDate = false,
    shouldBlockSingleDay,
    shouldDisplayInput = true,
    shouldStartEmptyDateSelection,
    shouldUseDatePickerBase = false,
    showISOWeekRange = false,
    showNumberOfNights = false,
    showSingleMonthPicker = false,
    singleDateRange = false,
    singleDateRangeFormat = 'MMM d, yy',
    singleDateToDisplayWeek = false,
    specialDays,
    totalAmount,
    twoStepChange,
    warning,
  }: DatePickerFieldProps & T): JSX.Element => {
    const [showDatePicker, setShowDatePicker] = useState(false)

    const isTinyMobile = useIsTinyMobile()

    const handleCloseDatePicker = () => {
      setShowDatePicker(false)
    }

    const handleOnRangeSelected = (range: DateRange<undefined>) => {
      if (!range.startDate && !range.endDate) {
        handleCloseDatePicker()
        onChange('')
        return
      }

      if (
        shouldBlockSingleDay &&
        range?.startDate &&
        range?.endDate &&
        isEqual(parseISODate(range.startDate), parseISODate(range.endDate))
      ) {
        onChange('')
        return
      }

      const value = getPeriodFromDates({
        fromDate: range?.startDate,
        toDate: range?.endDate,
      })

      onChange(value)
      handleCloseDatePicker()
    }

    const getPeriodByDatePickerType = () => {
      if (singleDateRange && !singleDateToDisplayWeek) {
        return { fromDate: dateValue, toDate: dateValue }
      }
      return getDatesFromPeriod(dateValue)
    }

    // Get period
    const customRangeDates =
      shouldAcceptEmptyDate && !dateValue
        ? {
            fromDate: originalRange ? originalRange.startDate : '',
            toDate: originalRange ? originalRange.endDate : '',
          }
        : getPeriodByDatePickerType()

    // Input Value
    const hasBothDates = customRangeDates.fromDate && customRangeDates.toDate
    const datesAreDifferent = customRangeDates.fromDate !== customRangeDates.toDate
    let inputValue = ''

    const formattedOriginalRange =
      originalRange?.startDate &&
      formatDateRange(
        originalRange?.startDate,
        originalRange?.endDate,
        'en-US',
        formatDateRangeExtraOptions,
      )

    if (originalRange) inputValue = String(formattedOriginalRange)

    if (hasBothDates && datesAreDifferent) {
      inputValue = formatDateRange(
        // Format to prevent formatDateRange timezone
        `${formatDate(customRangeDates.fromDate)}T00:00:00`,
        `${formatDate(customRangeDates.toDate)}T00:00:00`,
        'en-US',
        formatDateRangeExtraOptions,
      )
    } else if (hasBothDates && !datesAreDifferent) {
      inputValue = formatDate(customRangeDates.fromDate, singleDateRangeFormat)
    }

    // Get number of nights
    const numberOfNights =
      customRangeDates.fromDate !== customRangeDates.toDate &&
      totalNights(String(customRangeDates.fromDate), String(customRangeDates.toDate))

    if (showNumberOfNights && numberOfNights) {
      inputValue = `${inputValue} • ${numberOfNights}`
    }

    // Difference in ISO Weeks
    const differenceISOWeeks =
      customRangeDates.fromDate && customRangeDates.toDate
        ? differenceInCalendarISOWeeks(
            parseISODate(customRangeDates.toDate),
            parseISODate(customRangeDates.fromDate),
          )
        : 0

    if (showISOWeekRange && differenceISOWeeks > 0) {
      inputValue = `${inputValue} • ${differenceISOWeeks} w`
    }

    // Single date
    if (singleDateRange && !singleDateToDisplayWeek) {
      inputValue = customRangeDates.fromDate
        ? formatDate(customRangeDates.fromDate, singleDateRangeFormat)
        : ''
    }

    const Input = size?.small ? TextField.Small : TextField.Default

    const isBothDatesEqual =
      originalRange?.startDate &&
      isEqual(originalRange?.startDate, parseISODate(customRangeDates.fromDate)) &&
      isEqual(originalRange?.endDate, parseISODate(customRangeDates.toDate))

    const shouldShowCrossedOverDate = dateValue && originalRange && !isBothDatesEqual

    const DatePickerComponent = shouldUseDatePickerBase ? DateRangePickerBase : DateRangePicker

    return (
      <Component $isDisabled={disabled}>
        {shouldDisplayInput && (
          <Input
            id={id}
            inputRightIcon={!isTinyMobile ? <S.IconCalendarStyled /> : null}
            beforeValue={labelInternal}
            placeholder={placeholder}
            value={inputValue}
            disabled={disabled}
            error={error}
            warning={warning}
            inputLeftIcon={
              shouldShowCrossedOverDate && (
                <S.OriginalRangeContainer>
                  <S.OriginalRange>{formattedOriginalRange}</S.OriginalRange>
                </S.OriginalRangeContainer>
              )
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(event.currentTarget.value)
            }
            onClick={() => setShowDatePicker(true)}
            onFocus={event => {
              event.persist()
              event.target.blur()
            }}
            dataCy={inputDataCy}
            widthMaxContent={inputWidthMaxContent}
            className={className}
            afterValue={afterValue}
          />
        )}

        <DatePickerComponent
          show={showDatePicker || !shouldDisplayInput}
          className={pickerClassName || 'dateRangePicker'}
          rootClassName="rootDateRangePicker"
          blockedDates={blockedDates}
          startDate={customRangeDates.fromDate}
          endDate={customRangeDates.toDate}
          minDate={customPeriodMinDate}
          maxDate={customPeriodMaxDate}
          clearButtonLabel={clearButtonLabel}
          clearButtonColor={clearButtonColor}
          disableDaysBeforeToday={disableDaysBeforeToday}
          onChange={handleOnRangeSelected}
          onClose={handleCloseDatePicker}
          onClickOut={handleCloseDatePicker}
          singleDateRange={singleDateRange}
          showSingleMonthPicker={showSingleMonthPicker}
          twoStepChange={!singleDateRange || twoStepChange}
          minRangeLength={minRangeLength}
          horizontalAlignment={horizontalAlignment}
          originalRange={originalRange}
          totalAmount={totalAmount}
          dateTooltip={dateTooltip}
          customHeaderComponent={customDatePickerHeaderComponent}
          shouldStartEmpty={shouldStartEmptyDateSelection}
          firstDayOfWeek={firstDayOfWeek}
          specialDays={specialDays}
        >
          {children}
        </DatePickerComponent>
      </Component>
    )
  }

const DatePickerField = (): ReactNode => createDatePickerFieldComponent(S.Root)

DatePickerField.Default = createDatePickerFieldComponent(S.Root)
DatePickerField.Small = createDatePickerFieldComponent(S.Root, { small: true })

export { DatePickerField }
