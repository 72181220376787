import React, { FC, useEffect, useRef } from 'react'

import * as S from './MiddleEllipsis.styles'

interface MiddleEllipsisProps {
  data: string
  tailLength: number
  onCollapseChange?: (isCollapsed: boolean) => void
  keepFullWords?: boolean
  disabled?: boolean
}

export const MiddleEllipsis: FC<MiddleEllipsisProps> = ({
  data,
  keepFullWords,
  tailLength,
  onCollapseChange,
  disabled,
}) => {
  const showFullString = tailLength > data.length / 2

  const headRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const head = headRef.current
    if (!head) {
      return
    }
    const isEllipsisActive = head.offsetWidth < head.scrollWidth
    onCollapseChange?.(isEllipsisActive)
  }, [onCollapseChange, headRef?.current?.offsetWidth, headRef?.current?.scrollWidth])

  if (disabled || showFullString) {
    return <S.EllipsisContainer>{data}</S.EllipsisContainer>
  }

  const fixedTailLength = keepFullWords
    ? Math.max(data.slice(-(tailLength - data.slice(-tailLength).indexOf(' ') - 1)).length, 0)
    : tailLength

  const [head, tail] =
    data.match(new RegExp(`^(.+?)(.{${Math.min(data.length / 2, fixedTailLength)}})$`))?.slice(1) ??
    []

  return (
    <S.Container>
      <S.Head ref={headRef}>{head}</S.Head>
      {keepFullWords ? <>&nbsp;</> : null}
      <S.Tail>{tail}</S.Tail>
    </S.Container>
  )
}
