import React, { useState } from 'react'

import { IconChevronDown, IconChevronUp } from '@avantstay/backoffice-vectors'

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  size as middlewareSize,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'

import { Loading } from '../Loading'
import { SearchInput } from '../SearchInput'
import {
  CheckPickerItemTypeNew,
  CheckPickerNewProps,
  ICheckPickerSizeProps,
} from './CheckPicker.types'
import { useCheckPickerOptions } from './CheckPickerNew.hooks'
import * as S from './CheckPickerNew.styles'
import { InputValueWithTags } from './components/InputValueWithTags'
import { OptionsMenu } from './components/OptionsMenu'

const defaultOptionAll: CheckPickerItemTypeNew<string> = {
  value: 'all',
  label: 'All',
}

const CheckPicker = <OptionType extends string>({
  className,
  dropdownHeader,
  dropdownWidth,
  hideMenuActions = false,
  customOptionAll = defaultOptionAll,
  disabled = false,
  hideOptionAll,
  isLoadingOptions,
  onClearSelection,
  onChange,
  options,
  placeholder = 'Select options',
  placement = 'bottom-start',
  searchable = false,
  strategy = 'fixed',
  value,
  title,
  small,
}: CheckPickerNewProps<OptionType> & ICheckPickerSizeProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { context, refs, floatingStyles } = useFloating<HTMLElement>({
    placement,
    whileElementsMounted: autoUpdate,
    strategy,
    onOpenChange: setIsMenuOpen,
    open: isMenuOpen,
    middleware: [
      offset(5),
      flip(),
      middlewareSize({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: dropdownWidth || `${rects.reference.width}px`,
            maxWidth: dropdownWidth || `${rects.reference.width}px`,
          })
        },
        padding: 10,
      }),
    ],
  })

  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  const { mappedOptions, searchedOptions, handleSearchTerm } = useCheckPickerOptions({
    options,
    value,
    searchable,
    isMenuOpen,
  })

  const selectedOptions =
    value && value.length > 0
      ? options.filter(option => value?.includes(option.value))
      : hideOptionAll
        ? []
        : [{ label: 'All', value: 'all' }]

  return (
    <>
      <S.Container
        className={className}
        $active={isMenuOpen}
        onClick={() => {
          if (!disabled) setIsMenuOpen(!isMenuOpen)
        }}
        $disabled={disabled}
        $small={small}
        tabIndex={0}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <InputValueWithTags
          placeholder={placeholder}
          selectedOptions={selectedOptions}
          title={title}
        />

        {!mappedOptions.selectedOptions?.length ? (
          <S.DropdownIndicator disabled={disabled}>
            {isMenuOpen ? <IconChevronUp /> : <IconChevronDown />}
          </S.DropdownIndicator>
        ) : null}
      </S.Container>

      {isMenuOpen ? (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <S.DropdownContainer
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {dropdownHeader}
              {searchable ? (
                <SearchInput
                  onChange={handleSearchTerm}
                  iconSearchSize={{ width: 16, height: 16 }}
                />
              ) : null}

              {isLoadingOptions ? (
                <S.LoadingContainer>
                  <Loading size="4px" />
                </S.LoadingContainer>
              ) : (
                <OptionsMenu<OptionType>
                  value={value}
                  selectedOptions={mappedOptions.selectedOptions}
                  searchedOptions={searchedOptions}
                  onChange={onChange}
                  hideMenuActions={hideMenuActions}
                  hideOptionAll={hideOptionAll}
                  customOptionAll={customOptionAll}
                  onClearSelection={onClearSelection}
                />
              )}
            </S.DropdownContainer>
          </FloatingFocusManager>
        </FloatingPortal>
      ) : null}
    </>
  )
}

function CheckPickerSmall<OptionType extends string>(props: CheckPickerNewProps<OptionType>) {
  return <CheckPicker {...props} small />
}

CheckPicker.Default = CheckPicker
CheckPicker.Small = CheckPickerSmall

export { CheckPicker }
