import styled from 'styled-components'

import { _M_Normal, _S_Normal, _XS_Normal, ellipsis, mainColors } from '../../../../styles'
import { SelectFieldOptionProps, Size } from '../../SelectField.types'

export const Option = styled.div<SelectFieldOptionProps>`
  ${_M_Normal};
  min-height: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  text-align: left;
  background-color: ${mainColors.white};
  color: ${mainColors.black};
  padding: 14px 14px 14px 18px;
  cursor: pointer;
  line-height: normal;
  white-space: nowrap;

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? mainColors.neutralLight : mainColors.neutralExtraLight};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
    color: ${mainColors.neutralDark};
    cursor: not-allowed;
  `}

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: ${mainColors.neutralLight};
  `}

  ${({ isFocused }) =>
    isFocused &&
    `
    background-color: ${mainColors.neutralExtraLight};
    color: ${mainColors.black};
  `}

  ${({ isFocused, isSelected }) =>
    isFocused &&
    isSelected &&
    `
    background-color: ${mainColors.accentExtremelyLight};
    color: ${mainColors.accentMedium};
  `}

  ${({ size }) =>
    size === Size.Small &&
    `
      ${_S_Normal};
      min-height: 34px;
      height: 34px;
      padding: 7px 14px;

      &:hover {
         background-color: ${mainColors.neutralExtraLight};
      }
  `}

  ${({ isSelected, size }) =>
    size === Size.Small &&
    isSelected &&
    `
    background-color: ${mainColors.neutralLight};
    color: ${mainColors.black};

    &:hover {
       background-color: ${mainColors.neutralLight};
    }
  `}
`

export const OptionLabel = styled.div`
  ${ellipsis};
`

export const OptionSubtitle = styled.div`
  ${_XS_Normal};
  color: ${mainColors.neutralExtremelyDark};
  white-space: nowrap;
`

export const Dot = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${({ color }) => color};
`

export const TooltipContent = styled.div`
  text-align: center;
`
