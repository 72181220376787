import arriereBackoffice, {
  CaseRecordType,
  CaseSorting,
  CitationDetailsInput,
  OnboardingCasesInput,
  OwnerEscalationCasesInput,
  OwnerRelationCasesInput,
  SortOrder,
  TerminationRequestInput,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { Alert, mainColors } from '@avantstay/backoffice-core'

import {
  BulkEditProps,
  CaseCreationProps,
  CaseUpdateProps, // CreateCommentProps,
  GetCaseProps,
  UpdateCaseBasic,
} from '@new/domains/operations/screens/Cases/Cases.types'

const FieldMeta = {
  label: true,
  apiName: true,
}

const UserField = {
  id: true,
  name: true,
  email: true,
}

const Booking = {
  bookingId: true,
  sfdcBookingId: true,
  guestName: true,
  checkInDate: true,
  hash: true,
  checkOutDate: true,
  guestsCount: true,
  nightsCount: true,
}

const paginationSinglePage = { page: 1, size: 5000 }

// const Comment = {
//   comment: true,
//   authorName: true,
//   createdAt: true,
// }

export default {
  async getCases({
    caseOwnerIds,
    caseVendorIds,
    categories,
    createdAtPeriod,
    creatorIds,
    dueDatePeriod,
    homeIds,
    inStayGuestIssue,
    isRecurringCaseStatus,
    isAirbnbTripIssuesOnly,
    pagination,
    predefinedFilter,
    priorities,
    recordTypes,
    regionIds,
    searchTerm,
    showOffboarded,
    sortBy,
    sortOrder,
    statuses,
    subcategories,
    taskTypes,
  }: GetCaseProps) {
    return arriereBackoffice.queries.cases
      .raw({
        __alias: 'searchCases',
        searchCases: {
          __args: {
            categories,
            createdAtPeriod,
            creatorIds,
            dueDatePeriod,
            homeIds,
            inStayGuestIssue,
            isRecurringCaseStatus,
            isAirbnbTripIssuesOnly,
            pagination,
            predefinedFilter,
            priorities,
            recordTypes,
            regionIds,
            searchTerm,
            showOffboarded,
            sortBy,
            sortOrder,
            statuses,
            subcategories,
            taskTypes,
            caseOwnerIds,
            caseVendorIds,
          },
          count: {
            isgi: true,
            notIsgi: true,
          },
          countRecurringCases: {
            recurring: true,
            notRecurring: true,
          },
          values: {
            total: true,
            pages: true,
            results: {
              isAirbnbTripIssue: true,
              workOrderId: true,
              status: true,
              taskType: true,
              category: true,
              subcategory: true,
              priority: true,
              subject: true,
              managedUnitId: true,
              dueDate: true,
              caseNumber: true,
              caseOwner: UserField,
              caseVendor: {
                id: true,
                name: true,
              },
              createdAt: true,
              createdBy: UserField,
              inStayGuestIssue: true,
              caseSfdcId: true,
              recordType: true,
              recurringCaseSettingId: true,
              home: {
                id: true,
                name: true,
                region: {
                  id: true,
                  name: true,
                },
              },
            },
          },
        },
      })
      .then(({ data }) => {
        return data.searchCases
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return {
          count: {
            isgi: 0,
            notIsgi: 0,
          },
          countRecurringCases: {
            recurring: 0,
            notRecurring: 0,
          },
          values: {
            total: 0,
            pages: 0,
            results: [],
          },
        }
      })
  },
  // async getCaseDetails(id: string) {
  //   return arriereBackoffice.queries.cases
  //     .raw({
  //       __alias: 'getCaseDetails',
  //       find: {
  //         __args: {
  //           workOrderId: id,
  //         },
  //         recordType: true,
  //         workOrderId: true,
  //         status: FieldMeta,
  //         taskType: FieldMeta,
  //         category: FieldMeta,
  //         subcategory: FieldMeta,
  //         locationInHome: FieldMeta,
  //         managedUnitId: true,
  //         priority: true,
  //         subject: true,
  //         description: true,
  //         dueDate: true,
  //         booking: Booking,
  //         caseNumber: true,
  //         caseOwner: UserField,
  //         createdAt: true,
  //         createdBy: UserField,
  //         recurringCaseSettingId: true,
  //         billTo: true,
  //         parentCase: {
  //           workOrderId: true,
  //           caseNumber: true,
  //           subject: true,
  //           status: { label: true, apiName: true },
  //         },
  //         ownerOutreachRequired: true,
  //         inStayGuestIssue: true,
  //         airbnbRatingCategories: true,
  //         airbnbListingStatusFlag: true,
  //         isAirbnbTripIssue: true,
  //         comments: Comment,
  //         caseSfdcId: true,
  //         caseVendor: {
  //           id: true,
  //           name: true,
  //           baseInfo: {
  //             hourlyRate: true,
  //           },
  //         },
  //         home: {
  //           id: true,
  //           name: true,
  //           region: {
  //             id: true,
  //             name: true,
  //           },
  //         },
  //         images: {
  //           id: true,
  //           url: true,
  //         },
  //         permissionToEnter: true,
  //         fullyResolved: true,
  //         pendingInfo: true,
  //         recurringCaseSetting: {
  //           startDate: true,
  //           endDate: true,
  //           serviceName: true,
  //           frequency: true,
  //           frequencyInterval: true,
  //           weekDays: true,
  //         },
  //         relatedCases: {
  //           workOrderId: true,
  //           caseNumber: true,
  //           subject: true,
  //           status: { label: true, apiName: true },
  //         },
  //         caseOrigin: true,
  //         accountData: {
  //           id: true,
  //           name: true,
  //         },
  //         legalInvolved: true,

  //         guestIncidental: {
  //           guestIncidentalTypes: true,
  //           guestDamageDescription: true,
  //           totalAmountRequested: true,
  //           finalConfirmedCost: true,
  //           photosAttached: true,
  //           didGuestReportDamage: true,
  //           replacementOrRepair: true,
  //           chargeGuestForDamages: true,
  //           waivedChangeReasoning: true,
  //           waivedChangeDetails: true,
  //           percentageOfReservationTotal: true,
  //         },
  //         billToSection: {
  //           reprocessBillTo: true,
  //           billTo: true,
  //           billToOverride: true,
  //           billToOverrideDetails: true,
  //           hasExpense: true,
  //           ownerOutreachRequired: true,
  //           ownerContacted: true,
  //           ownerApprovedStatus: true,
  //         },
  //         guestIncidentalExperience: {
  //           assignedGxAgent: true,
  //           guestIncidentalsStatus: true,
  //           guestIncidentalsProcedure: true,
  //           guestNotifiedDate: true,
  //           totalAmountReceived: true,
  //         },
  //         citationDetails: {
  //           dateReceived: true,
  //           receivedBy: true,
  //           infraction: true,
  //           additionalDetails: true,
  //           didYouGetFine: {
  //             fineAmount: true,
  //             finePaid: true,
  //             datePaid: true,
  //             paymentMethod: true,
  //           },
  //           isThisStrikeTowardOurPermit: true,
  //           partyAtFault: true,
  //         },
  //         ownerRelationCases: {
  //           issueType: true,
  //           nextSteps: true,
  //           resolutionDescription: true,
  //           needReview: true,
  //           contactName: true,
  //           attorneyInvolved: true,
  //           reservationAffected: true,
  //           ownerChargeback: true,
  //           guestChargeback: true,
  //           guestChargebackResolved: true,
  //         },
  //         ownerEscalationCases: {
  //           escalationReason: true,
  //           description: true,
  //           resolutionActivated: true,
  //           escalationAmount: true,
  //         },
  //         terminationRequest: {
  //           terminationNoticeDate: true,
  //           terminationDate: true,
  //           terminationType: true,
  //           terminationReason: true,
  //           secondTerminationReason: true,
  //           ltmProfitAtCaseCreation: true,
  //           contributionMarginAtCaseCreation: true,
  //           futurePropertyPlans: true,
  //         },
  //         vpmManaged: true,
  //         vpmAgent: true,
  //         vpmAssignment: true,
  //         quote: true,
  //         quoteDetails: true,
  //         quoteApprover: true,
  //         quoteApproverDetails: {
  //           id: true,
  //           name: true,
  //         },
  //         expenses: {
  //           id: true,
  //           status: true,
  //           amount: true,
  //           description: true,
  //           createdAt: true,
  //           updatedAt: true,
  //           receipts: {
  //             id: true,
  //             url: true,
  //             fileName: true,
  //           },
  //           billTo: {
  //             items: {
  //               amount: true,
  //               assignee: true,
  //             },
  //           },
  //           billToOverrideReason: true,
  //           paymentMethod: {
  //             orderDotCo: {
  //               transactionDate: true,
  //             },
  //             vendorInvoice: {
  //               performedAt: true,
  //               receivedAt: true,
  //               isRecurring: true,
  //               serviceProvider: {
  //                 id: true,
  //                 name: true,
  //               },
  //             },
  //             selfInvoicingMT: { laborMinutes: true },
  //           },
  //           items: {
  //             description: true,
  //             amount: true,
  //             isOutOfPocket: true,
  //             vendorRate: true,
  //           },
  //           workOrder: {
  //             workOrderId: true,
  //           },
  //         },
  //       },
  //     })
  //     .then(({ data }) => data.find)
  //     .catch(error => {
  //       Alert.Error((error as Error).message)
  //       return Promise.resolve(undefined)
  //     })
  // },
  // async getExternalImages(workOrderId: string) {
  //   return arriereBackoffice.queries.workOrders
  //     .raw({
  //       __alias: 'getExternalImages',
  //       externalImages: {
  //         __args: { workOrderId },
  //       },
  //     })
  //     .then(({ data }) => {
  //       return data?.externalImages
  //     })
  //     .catch(error => {
  //       Alert.Error((error as Error).message)
  //       return []
  //     })
  // },
  async getHomesSimpleList(name?: string, regionIds?: string[]) {
    return arriereBackoffice.queries.homes
      .raw({
        __alias: 'getHomesSimpleList',
        __args: { name, regionIds, pagination: paginationSinglePage },
        results: {
          id: true,
          name: true,
          title: true,
          isAncestor: true,
          region: {
            name: true,
          },
        },
      })
      .then(({ data }) => {
        return data.results
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return []
      })
  },
  async getProperties(name?: string, regionIds?: string[]) {
    return arriereBackoffice.queries.properties
      .raw({
        __alias: 'searchHomeToManagedUnits',
        searchHomeToManagedUnits: {
          __args: { searchString: name ?? '', regionIds, pagination: paginationSinglePage },
          results: {
            home: {
              id: true,
              name: true,
              title: true,
              isAncestor: true,
              region: {
                name: true,
              },
            },
          },
        },
      })
      .then(({ data }) => {
        return data.searchHomeToManagedUnits.results.map(property => property.home)
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return []
      })
  },
  async searchCaseOwners(search: string) {
    return arriereBackoffice.queries
      .cases({
        __alias: 'searchCaseOwners',
        searchCaseOwners: {
          __args: {
            searchString: search,
          },
          id: true,
          name: true,
          email: true,
        },
      })
      .then(results => {
        return results.searchCaseOwners
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async searchCaseBookingsFiltered(searchString: string, homeId: string) {
    return arriereBackoffice.queries
      .caseBookings({
        __alias: 'searchCaseBookings',
        searchCaseBookings: {
          __args: {
            homeId,
            searchString,
          },
          past: Booking,
          ongoing: Booking,
          upcoming: Booking,
        },
      })
      .then(results => {
        return results.searchCaseBookings
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async searchCaseBookings(homeId: string) {
    return arriereBackoffice.queries
      .caseBookings({
        __alias: 'getBookings',
        getBookings: {
          __args: {
            homeId,
          },
          past: Booking,
          ongoing: Booking,
          upcoming: Booking,
        },
      })
      .then(results => {
        return results.getBookings
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },

  async searchCaseVendors(search: string) {
    return arriereBackoffice.queries
      .cases({
        __alias: 'searchCaseVendors',
        searchCaseVendors: {
          __args: {
            searchString: search,
          },
          id: true,
          name: true,
        },
      })
      .then(results => {
        return results.searchCaseVendors
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async getPropertyCases(homeIds?: string[]) {
    return arriereBackoffice.queries.cases
      .raw({
        __alias: 'getPropertyCases',
        searchCases: {
          __args: {
            homeIds,
            isAirbnbTripIssuesOnly: true,
            sortBy: CaseSorting.createdAt,
            sortOrder: SortOrder.descending,
          },
          values: {
            results: {
              workOrderId: true,
              status: true,
              subject: true,
              dueDate: true,
              isAirbnbTripIssue: true,
            },
          },
        },
      })
      .then(({ data }) => {
        return data.searchCases.values.results
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async getCaseInputs(recordType: CaseRecordType = CaseRecordType.fieldOps) {
    return arriereBackoffice.queries
      .cases({
        __alias: 'getCaseInputs',
        getCaseInputs: {
          __args: {
            recordType,
          },
          caseTypes: {
            caseType: FieldMeta,
            categories: {
              category: FieldMeta,
              subcategories: FieldMeta,
            },
          },
          locationsInHome: FieldMeta,
          statuses: FieldMeta,
          caseOwners: {
            __args: {
              recordType,
            },
            id: true,
            name: true,
          },
          vendors: {
            __args: {
              recordType,
            },
            id: true,
            name: true,
          },
          creators: {
            __args: {
              recordType,
            },
            id: true,
            name: true,
          },
        },
      })
      .then(results => {
        return results.getCaseInputs
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async createCase(
    caseCreationInput: CaseCreationProps,
    notificationCallback: (id: string) => void,
  ) {
    return arriereBackoffice.mutations
      .cases({
        __alias: 'createCase',
        createCase: {
          __args: caseCreationInput,
        },
      })
      .then(results => {
        Alert.Success(`Case successfully created.`, {
          actions: [
            {
              label: 'Open created case',
              color: mainColors.accentMedium,
              onClick: () => notificationCallback(results.createCase),
            },
          ],
        })
        return results.createCase
      })
      .catch(error => {
        const returnedError = (error as Error).message
        try {
          const parseError = JSON.parse(returnedError)
          if (parseError?.length) {
            Alert.Error(parseError[0].message)
          } else {
            Alert.Error(returnedError)
          }
        } catch (e) {
          Alert.Error(returnedError)
        }
      })
  },
  async updateCase(caseCreationInput: CaseUpdateProps) {
    return arriereBackoffice.mutations
      .cases({
        __alias: 'updateCase',
        updateCase: {
          __args: { caseUpdate: caseCreationInput },
        },
      })
      .then(results => {
        Alert.Success(`Case successfully updated.`)
        return results.updateCase
      })
      .catch(error => {
        const returnedError = (error as Error).message
        try {
          const parseError = JSON.parse(returnedError)
          if (parseError?.length) {
            Alert.Error(parseError[0].message)
          } else {
            Alert.Error(returnedError)
          }
        } catch (e) {
          Alert.Error(returnedError)
        }
      })
  },
  async updateCaseBasic(caseData: UpdateCaseBasic) {
    return arriereBackoffice.mutations
      .cases({
        __alias: 'updateCaseBasic',
        updateCaseBasic: {
          __args: { caseUpdate: caseData },
        },
      })
      .then(results => {
        Alert.Success(`Case successfully updated.`)
        return results.updateCaseBasic
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  // async closeCase(workOrderId: string) {
  //   return arriereBackoffice.mutations
  //     .cases({
  //       __alias: 'closeCase',
  //       closeCase: {
  //         __args: { workOrderId },
  //       },
  //     })
  //     .then(results => {
  //       Alert.Success(`Case successfully closed.`)
  //       return results.closeCase
  //     })
  //     .catch(error => {
  //       Alert.Error((error as Error).message)
  //       return undefined
  //     })
  // },
  // async deleteCase(workOrderId: string) {
  //   return arriereBackoffice.mutations
  //     .cases({
  //       __alias: 'deleteCase',
  //       deleteCase: {
  //         __args: { workOrderId },
  //       },
  //     })
  //     .then(results => {
  //       Alert.Success(`Case successfully deleted.`)
  //       return results.deleteCase
  //     })
  //     .catch(error => {
  //       Alert.Error((error as Error).message)
  //       return undefined
  //     })
  // },
  // async createComment({
  //   workOrderId,
  //   comment,
  //   taggedUserIds,
  //   showNotification = true,
  // }: CreateCommentProps) {
  //   return arriereBackoffice.mutations
  //     .cases({
  //       __alias: 'createCaseComment',
  //       createCaseComment: {
  //         __args: { workOrderId, comment, taggedUserIds },
  //       },
  //     })
  //     .then(results => {
  //       if (showNotification) {
  //         Alert.Success(`Case comment successfully submited.`)
  //       }
  //       return results.createCaseComment
  //     })
  //     .catch(error => {
  //       Alert.Error((error as Error).message)
  //       return undefined
  //     })
  // },
  getBackofficeUsers(name: string) {
    return arriereBackoffice.queries.backOfficeUsers({
      __args: { terms: name },
      id: true,
      name: true,
    })
  },
  bulkEdit(payload: BulkEditProps) {
    return arriereBackoffice.mutations.cases({
      __alias: 'bulkEdit',
      updateCases: {
        __args: { ...payload },
        workOrderId: true,
        caseNumber: true,
        succeed: true,
        errorMessage: true,
      },
    })
  },
  async getCaseHistory(workOrderId: string) {
    return arriereBackoffice.queries
      .cases({
        __alias: 'getCaseHistory',
        caseHistories: {
          __args: {
            workOrderId,
          },
          workOrderId: true,
          createdAt: true,
          userId: true,
          originalValue: true,
          newValue: true,
          fieldName: true,
          source: true,
          author: { id: true, name: true, email: true },
        },
      })
      .then(results => {
        return results.caseHistories
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return []
      })
  },
  async getUserById(id: string) {
    return arriereBackoffice.queries
      .userById({
        __alias: 'getUserById',
        __args: {
          id,
        },
        name: true,
        id: true,
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return null
      })
  },
  async createCaseByInputs(
    createCaseByInputs: {
      citationDetailsInput?: CitationDetailsInput
      terminationRequest?: TerminationRequestInput
      ownerRelationCases?: OwnerRelationCasesInput
      ownerEscalationCases?: OwnerEscalationCasesInput
    },
    notificationCallback: (id: string) => void,
  ) {
    return arriereBackoffice.mutations
      .cases({
        __alias: 'createCaseByInputs',
        createCaseByInputs: {
          __args: createCaseByInputs,
        },
      })
      .then(results => {
        Alert.Success(`Case successfully created.`, {
          actions: [
            {
              label: 'Open created case',
              color: mainColors.accentMedium,
              onClick: () => notificationCallback(results.createCaseByInputs),
            },
          ],
        })
        return results.createCaseByInputs
      })
      .catch(error => {
        const returnedError = (error as Error).message
        try {
          const parseError = JSON.parse(returnedError)
          if (parseError?.length) {
            Alert.Error(parseError[0].message)
          } else {
            Alert.Error(returnedError)
          }
        } catch (e) {
          Alert.Error(returnedError)
        }
      })
  },
  async updateCaseByInputs(updateCaseByInputs: {
    workOrderId: string
    citationDetailsInput?: CitationDetailsInput
    terminationRequest?: TerminationRequestInput
    ownerRelationCases?: OwnerRelationCasesInput
    onboardingCases?: OnboardingCasesInput
  }) {
    return arriereBackoffice.mutations
      .cases({
        __alias: 'updateCaseByInputs',
        updateCaseByInputs: {
          __args: updateCaseByInputs,
        },
      })
      .then(results => {
        return results.updateCaseByInputs
      })
      .catch(error => {
        const returnedError = (error as Error).message
        try {
          const parseError = JSON.parse(returnedError)
          if (parseError?.length) {
            Alert.Error(parseError[0].message)
          } else {
            Alert.Error(returnedError)
          }
        } catch (e) {
          Alert.Error(returnedError)
        }
      })
  },

  async getRelatedCase(searchTerm: string) {
    return arriereBackoffice.queries.cases
      .raw({
        __alias: 'getRelatedCase',
        searchCases: {
          __args: {
            searchTerm,
            pagination: {
              page: 1,
              size: 99999,
            },
          },
          values: {
            results: {
              workOrderId: true,
              status: true,
              subject: true,
              caseNumber: true,
              home: {
                id: true,
                name: true,
                region: {
                  id: true,
                  name: true,
                },
              },
            },
          },
        },
      })
      .then(({ data }) => {
        return data.searchCases?.values?.results
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async getAccounts(terms: string) {
    return arriereBackoffice.queries.accounts
      .raw({
        __alias: 'getAccounts',
        getAccounts: {
          __args: {
            terms,
          },
          id: true,
          name: true,
        },
      })
      .then(({ data }) => {
        return data.getAccounts
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
}
